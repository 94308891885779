<template>
  <div class="main-body">
    <div class="page-attention">
      <p>如果不选择上级分类默认为顶级分类</p>
      <p>如果选择上级分类，那么新增的分类则为被选择上级分类的子分类</p>
    </div>
    <a-divider />
    <a-form :model="formState" autocomplete="off" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSubmit">
      <a-form-item label="分类名称" name="category_name" :rules="[{ required: true, message: '请输入分类名称!' }]">
        <a-input class="half-input" v-model:value="formState.category_name" placeholder="请输入分类名称" />
      </a-form-item>
      <a-space align="baseline">
        <a-form-item label="上级分类" name="parent_first">
          <a-select class="medium-input" v-model:value="formState.parent_first">
            <a-select-option value="native">服装</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label-col="labelColSecond" label="二级" name="parent_second">
          <a-select class="medium-input" v-model:value="formState.parent_second">
            <a-select-option value="native">服装</a-select-option>
          </a-select>
        </a-form-item>
      </a-space>
      <a-form-item label="分类图标" name="category_image">
        <CsImage v-model:imgOne="formState.category_image" />
      </a-form-item>
      <a-form-item label="分类广告图" name="ad_image">
        <CsImage v-model:imgOne="formState.ad_image" />
      </a-form-item>
      <a-form-item label="广告链接" name="ad_link">
        <a-input class="half-input" v-model:value="formState.ad_link" />
      </a-form-item>
      <a-form-item label="关键字" name="category_keyword" extra="用于网站搜索引擎的优化，关键字之间请用英文逗号分隔">
        <a-input class="half-input" v-model:value="formState.category_keyword" placeholder="请输入关键字" />
      </a-form-item>
      <a-form-item label="分类描述" name="category_desc">
        <a-textarea class="half-input" v-model:value="formState.category_desc" placeholder="请输入分类描述" allow-clear />
      </a-form-item>
      <a-form-item label="排序" name="sort" extra="数字越大，排序越靠前，数字重复，则最新添加的靠前。">
        <a-input-number class="medium-input" v-model:value="formState.sort" :min="0" :max="1000000" />
      </a-form-item>
      <a-form-item label="是否显示" name="is_show">
        <a-radio-group v-model:value="formState.is_show">
          <a-radio value="1">显示</a-radio>
          <a-radio value="0">隐藏</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item class="form-submit-layout">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive } from "vue";
import CsImage from "@/components/UpImage";

export default defineComponent({
  components: {
    CsImage,
  },
  setup() {
    const formState = reactive({
      category_name: "",
      parent_first: "0",
      parent_second: "0",
      category_image: "",
      ad_image: "",
      ad_link: "",
      category_keyword: "",
      category_desc: "",
      sort: "0",
      is_show: "1",
    });

    const onSubmit = () => {};

    return {
      labelCol: {
        style: {
          width: "100px",
        },
      },
      labelColSecond: {
        style: {
          width: "44px",
        },
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>